.home-popup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.home-popup__content {
  background-color: #f8f8f8;
  border-radius: 8px;
  width: 90%;
  max-width: 1300px;
  height: 700px;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: popup-fade-in 0.3s ease;
}

.home-popup__close {
  position: absolute;
  top: -40px;
  right: -90px;
  background: none;
  border: none;
  font-size: 22px;
  cursor: pointer;
}

@keyframes popup-fade-in {
  from {
      opacity: 0;
      transform: scale(0.95);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

/* Mobile View */
@media screen and (max-width: 768px) {
  .home-popup__content {
      width: 50%; /* Adjust width for mobile */
      height: auto; /* Allow height to adjust based on content */
      max-width: 500px;
      max-height: 80%; /* Limit the height for mobile view */
      padding: 15px; /* Adjust padding */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow for mobile */
  }

  .home-popup__close {
      top: -30px; /* Adjust position of the close button */
      right: -50px;
      font-size: 20px; /* Smaller close button */
  }
}

/* Smaller mobile view */
@media screen and (max-width: 480px) {
  .home-popup__content {
      width: 90%; /* Full width on very small screens */
      max-width: 100%; /* Ensure no max width */
      padding: 10px; /* Less padding */
  }

  .home-popup__close {
      top: -50px; /* Adjust close button further for small screens */
      right: -60px;
      font-size: 18px; /* Even smaller close button */
  }
}
